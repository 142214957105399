import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/home.module.css';

const Home = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.backgroundImage}></div>
      
      {/* Title for the Categories Section */}
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Categories</h2>
      </div>
      
      <div className={styles.content}>
        <Link to="/categories/food" className={`${styles.box} ${styles.food}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Food</div>
        </Link>
        <Link to="/categories/places" className={`${styles.box} ${styles.places}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Places</div>
        </Link>
        <Link to="/categories/history" className={`${styles.box} ${styles.history}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>History</div>
        </Link>
        <Link to="/categories/culture" className={`${styles.box} ${styles.culture}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Culture</div>
        </Link>
        <Link to="/categories/nature" className={`${styles.box} ${styles.nature}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Nature</div>
        </Link>
        <Link to="/categories/activities" className={`${styles.box} ${styles.activities}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Activities</div>
        </Link>
        <Link to="/categories/events" className={`${styles.box} ${styles.events}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Events</div>
        </Link>
        <Link to="/categories/future" className={`${styles.box} ${styles.future}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Future</div>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
